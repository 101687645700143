body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: rgba(17,17,17,1);
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
.shadow{
  

}
.shadow:hover{
  box-shadow: -3px 3px rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
  transition: all 0.3s;

  
}
/* Track */
::-webkit-scrollbar-track {
  background: #f3f3f3; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #111; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vw;
  background-color:"red"
}
.perspective {
  width: 100%;
  perspective: 1000px;
}

#top {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.card {
  width: 270px;
  height: 413px;
  margin: auto;
  box-shadow: 0 70px 63px -60px #494848;
  transform-style: preserve-3d;
  transition: transform 0.05s linear;
}
.card .thumb {
  background: url("https://i.pinimg.com/564x/f4/81/4d/f4814d2d0e635505eaa6971704cbed79.jpg") no-repeat center;
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.card .thumb:after {
  background: inherit;
  content: "";
  display: block;
  position: absolute;
  left: -60px;
  top: 40px;
  width: 100%;
  height: 108%;
  z-index: -1;
  filter: blur(55px);
}
.card h2 {
  position: absolute;
  top: 0;
  left: -60px;
  font-size: 40px;
  font-weight: 100;
  transform: translateZ(80px);
}
.card span {
  position: absolute;
  bottom: 40px;
  right: -280px;
  font-size: 37px;
  font-weight: 600;
  transform: translateZ(35px);
}
.glass-btn {
  width:20vw;
  height:50vh;
  background-color: #111;
  color:#f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.glass-btn-m {
  width:70vw;
  height:30vh;
  background-color: #111;
  color:#f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}


.glass-btn.amber-btn {
  box-shadow: 0px 20px 20px -17px rgba(46, 45, 46, 0.53);
}
.glass-btn.amber-btn:hover {
  box-shadow: 0px 20px 35px -16px rgba(202, 202, 202, 0.6);
  color:whitesmoke
}
.glass-btn.amber-btn .content {
  font-size: 10; 
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #ff8d00, #f7ad07 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10px 37px rgba(255, 94, 16, 0.7);
}
.glass-btn:hover {
  background-color: #5b3f91;
  transform: translateY(-5px);
  transition: all 0.3s;
}

.svg_icons {
  transform: scale(2);
}
.float{
  transform: translatey(0px);
	
  animation: float 6s ease-in-out infinite;
}
@keyframes float {
	0% {
	
		transform: translatey(0px);
	}
	50% {
		
		transform: translatey(-30px);
	}
	100% {
	
		transform: translatey(0px);
	}
}